import { Button, Spinner } from "react-bootstrap";
import "../../css/pages/Checkout.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import api from "../api";
function Checkout({ ...props }) {
  const { isMobile } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  const [sameBilling, setSameBilling] = useState(false);

  const [shippingFirstName, setShippingFirstName] = useState("");
  const [shippingLastName, setShippingLastName] = useState("");
  const [shippingNumber, setShippingNumber] = useState("");
  const [shippingEmail, setShippingEmail] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingAddressTwo, setShippingAddressTwo] = useState("");
  const [shippingProvince, setShippingProvince] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingZip, setShippingZip] = useState("");

  const [loadingPayment, setLoadingPayment] = useState(false);

  const cart = useSelector((state) => state.cart);
  const token = "";

  const isAnyFieldEmpty = () => {
    return (
      firstName === "" ||
      lastName === "" ||
      number === "" ||
      email === "" ||
      address === "" ||
      addressTwo === "" ||
      province === "" ||
      city === "" ||
      zip === "" ||
      shippingFirstName === "" ||
      shippingLastName === "" ||
      shippingNumber === "" ||
      shippingEmail === "" ||
      shippingAddress === "" ||
      shippingAddressTwo === "" ||
      shippingProvince === "" ||
      shippingCity === "" ||
      shippingZip === ""
    );
  };

  const handleCheckout = async () => {
    try {
      setLoadingPayment(true);
      if (isAnyFieldEmpty()) {
        console.log("empty");
        return;
      }
      const userObject = {
        firstName,
        lastName,
        number,
        email,
        address,
        addressTwo,
        province,
        city,
        zip,
        sameBilling,
        shippingFirstName: sameBilling ? firstName : shippingFirstName,
        shippingLastName: sameBilling ? lastName : shippingLastName,
        shippingNumber: sameBilling ? number : shippingNumber,
        shippingEmail: sameBilling ? email : shippingEmail,
        shippingAddress: sameBilling ? address : shippingAddress,
        shippingAddressTwo: sameBilling ? addressTwo : shippingAddressTwo,
        shippingProvince: sameBilling ? province : shippingProvince,
        shippingCity: sameBilling ? city : shippingCity,
        shippingZip: sameBilling ? zip : shippingZip,
      };
      const response = await fetch(api.baseURL + "payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: cart,
          userObject,
        }),
      });

      const responseJson = await response.json();
      setLoadingPayment(false);
      window.payfast_do_onsite_payment(
        {
          uuid: `${responseJson.identifier}`,
        },
        async function (result) {
          if (result === true) {
            const successResponse = await fetch(
              api.baseURL +
                `payment/success/${email}/${responseJson.ref}/${responseJson.identifier}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
            if (!successResponse) {
              console.log("Could not confirm payment.");

              // showErrorMessage('Error confirming payment.');
            } else {
              console.log("Payment success");
              // showSuccessMessage('Payment confirmed.');
              // dispatch(clearCart());
            }
          } else {
            const cancelResponse = await fetch(api.baseURL + "payment/cancel", {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                identifier: responseJson?.identifier,
              }),
            });
            if (!cancelResponse) {
              // showErrorMessage('Error canceling payment.');
            } else {
              // showInfoMessage('Purchase cancelled.');
            }
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const CartItem = ({ item }) => {
    const idKey = uuidv4();
    return (
      <li
        key={idKey}
        className="list-group-item d-flex justify-content-between lh-sm"
      >
        <div>
          <div className="d-flex">
            <span className=" my-0 me-2">{item.qty}x </span>
            <h6 className="my-0">{item.name}</h6>
          </div>
          <small className="text-body-secondary">{item.category}</small>
        </div>
        <span className="text-body-secondary">R{item.price}</span>
      </li>
    );
  };

  useEffect(() => {
    if (sameBilling) {
      setShippingFirstName(firstName);
      setShippingLastName(lastName);
      setShippingNumber(number);
      setShippingEmail(email);
      setShippingAddress(address);
      setShippingAddressTwo(addressTwo);
      setShippingProvince(province);
      setShippingCity(city);
      setShippingZip(zip);
    } else {
      setShippingFirstName("");
      setShippingLastName("");
      setShippingNumber("");
      setShippingEmail("");
      setShippingAddress("");
      setShippingAddressTwo("");
      setShippingProvince("");
      setShippingCity("");
      setShippingZip("");
    }
  }, [
    sameBilling,
    firstName,
    lastName,
    number,
    email,
    address,
    addressTwo,
    province,
    city,
    zip,
  ]);

  return (
    <div className="container checkoutContainer">
      <div className="py-5 text-center">
        <div className="row g-5">
          <div
            className={`col-md-5 col-lg-5 ${
              isMobile ? "order-sm-last" : "order-md-last"
            }`}
          >
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-danger">Your cart</span>
              <span className="badge bg-danger rounded-pill">
                {cart.quantity}
              </span>
            </h4>
            <ul className="list-group mb-3">
              {cart.items.map((item) => (
                <CartItem item={item} />
              ))}
              <li
                key="totalElement"
                className="list-group-item d-flex justify-content-between"
              >
                <span>Total (ZAR)</span>
                <strong>R{cart.total}</strong>
              </li>
            </ul>
            <hr className="my-4"></hr>
            {loadingPayment ? (
              <Button
                variant="danger"
                className="w-100 btn btn-primary-hs text-white btn-lg"
              >
                <Spinner animation="border" size="md" role="status" />
              </Button>
            ) : (
              <Button
                variant="danger"
                className="w-100 btn btn-primary-hs text-white btn-lg"
                type="submit"
                disabled={cart.quantity <= 0}
                onClick={() => {
                  handleCheckout();
                }}
              >
                Pay now
              </Button>
            )}
          </div>
          <div className="col-md-7 col-lg-7">
            <h4 className="mb-5">Billing address</h4>
            <form className="needs-validation text-start" noValidate>
              <div className="row g-3">
                <div className="col-sm-6">
                  <label htmlFor="firstname" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                    placeholder=""
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div className="col-sm-6 text-start">
                  <label htmlFor="lastName" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                    placeholder=""
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3 text-start">
                <label htmlFor="username" className="form-label">
                  Phone number
                </label>
                <div className="input-group has-validation">
                  <span className="input-group-text">@</span>
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    onChange={(event) => {
                      setNumber(event.target.value);
                    }}
                    placeholder="Phone number"
                  />
                  <div className="invalid-feedback">
                    Your number is required.
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="email" className="form-label">
                  Email <span className="text-body-secondary">(Optional)</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="you@example.com"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                <div className="invalid-feedback">
                  Please enter a valid email address for shipping updates.
                </div>
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="1234 Main St"
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                  required=""
                />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="address2" className="form-label">
                  Address 2{" "}
                  <span className="text-body-secondary">(Optional)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  onChange={(event) => {
                    setAddressTwo(event.target.value);
                  }}
                  placeholder="Apartment or suite"
                />
              </div>
              <div className="row">
                <div className="col-md-5 mt-3">
                  <label htmlFor="province" className="form-label">
                    Province
                  </label>
                  <select
                    className="form-select"
                    id="province"
                    required=""
                    onChange={(event) => {
                      setProvince(event.target.value);
                    }}
                  >
                    <option value="">Choose...</option>
                    <option>Eastern Cape</option>
                    <option>Free state</option>
                    <option>Gauteng</option>
                    <option>KwaZulu-Natal</option>
                    <option>Limpopo</option>
                    <option>Mpumalanga</option>
                    <option>Northern Cape</option>
                    <option>North West</option>
                    <option>Western Cape</option>
                  </select>
                  <div className="invalid-feedback">
                    Please select a valid country.
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    placeholder=""
                    onChange={(event) => {
                      setCity(event.target.value);
                    }}
                    required=""
                  />
                  <div className="invalid-feedback">
                    Please provide a valid state.
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <label htmlFor="zip" className="form-label">
                    Zip
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    placeholder=""
                    onChange={(event) => {
                      setZip(event.target.value);
                    }}
                    required=""
                  />
                  <div className="invalid-feedback">Zip code required.</div>
                </div>
              </div>
              <hr className="my-4"></hr>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(event) => {
                    setSameBilling(event.target.checked);
                    console.log(event.target.checked);
                  }}
                  id="same-address"
                />
                <label className="form-check-label" htmlFor="same-address">
                  Shipping address is the same as my billing address
                </label>
              </div>

              {!sameBilling && (
                <div>
                  <hr className="my-4"></hr>
                  <h4 className="mt-3 mb-5">Shipping address</h4>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <label htmlFor="firstname" className="form-label">
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        onChange={(event) => {
                          setShippingFirstName(event.target.value);
                        }}
                        placeholder=""
                      />
                      <div className="invalid-feedback">
                        Valid first name is required.
                      </div>
                    </div>
                    <div className="col-sm-6 text-start">
                      <label htmlFor="lastName" className="form-label">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        onChange={(event) => {
                          setShippingLastName(event.target.value);
                        }}
                        placeholder=""
                      />
                      <div className="invalid-feedback">
                        Valid last name is required.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3 text-start">
                    <label htmlFor="username" className="form-label">
                      Phone number
                    </label>
                    <div className="input-group has-validation">
                      <span className="input-group-text">@</span>
                      <input
                        type="text"
                        className="form-control"
                        id="number"
                        onChange={(event) => {
                          setShippingNumber(event.target.value);
                        }}
                        placeholder="Phone number"
                      />
                      <div className="invalid-feedback">
                        Your number is required.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label htmlFor="email" className="form-label">
                      Email{" "}
                      <span className="text-body-secondary">(Optional)</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      onChange={(event) => {
                        setShippingEmail(event.target.value);
                      }}
                      placeholder="you@example.com"
                    />
                    <div className="invalid-feedback">
                      Please enter a valid email address for shipping updates.
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      placeholder="1234 Main St"
                      onChange={(event) => {
                        setShippingAddress(event.target.value);
                      }}
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please enter your shipping address.
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label htmlFor="address2" className="form-label">
                      Address 2{" "}
                      <span className="text-body-secondary">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address2"
                      placeholder="Apartment or suite"
                      onChange={(event) => {
                        setShippingAddressTwo(event.target.value);
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-5 mt-3">
                      <label htmlFor="province" className="form-label">
                        Province
                      </label>
                      <select
                        className="form-select"
                        id="province"
                        onChange={(event) => {
                          setShippingProvince(event.target.value);
                        }}
                        required=""
                      >
                        <option value="">Choose...</option>
                        <option>Eastern Cape</option>
                        <option>Free state</option>
                        <option>Gauteng</option>
                        <option>KwaZulu-Natal</option>
                        <option>Limpopo</option>
                        <option>Mpumalanga</option>
                        <option>Northern Cape</option>
                        <option>North West</option>
                        <option>Western Cape</option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid country.
                      </div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder=""
                        onChange={(event) => {
                          setShippingCity(event.target.value);
                        }}
                        required=""
                      />
                      <div className="invalid-feedback">
                        Please provide a valid state.
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <label htmlFor="zip" className="form-label">
                        Zip
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="zip"
                        placeholder=""
                        onChange={(event) => {
                          setShippingZip(event.target.value);
                        }}
                        required=""
                      />
                      <div className="invalid-feedback">Zip code required.</div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
