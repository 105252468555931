import "../../css/App.css";
import "../../css/AdminTables.css";
import { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";

import api from "../api";

function RemoveProduct() {
  const token = localStorage.getItem("token-husaria") ?? null;
  const [products, setProducts] = useState([]);

  async function getProducts() {
    try {
      const response = await fetch(api.baseURL + `products`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      setProducts(jsonResponse.products);
      if (!response.ok) {
        throw new Error(jsonResponse.error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteOrder(rowData) {
    try {
      const response = await fetch(
        api.baseURL + `product/${rowData._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const jsonResponse = await response.json();
      if (!response.ok) {
        throw new Error(jsonResponse.error);
      }
      alert("Product removed");
      await getProducts();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Table className="shadow-sm">
      <thead>
        <tr>
          <th align="align-center">Title</th>
          <th align="left">Category</th>
          <th align="left">Amount</th>
          <th align="left"></th>
        </tr>
      </thead>
      <tbody className="text-font-light">
        {products.map((entry, index) => {
          return (
            <tr key={index}>
              <td>{entry.title}</td>
              <td>{entry.category}</td>
              <td>{entry.amount}</td>
              <td align="right">
                <Button
                  variant="link"
                  className="d-flex text-danger align-items-center"
                  size="sm"
                  onClick={() => {
                    deleteOrder(entry);
                  }}
                >
                  delete
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default RemoveProduct;
