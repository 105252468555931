import "../../css/pages/Home.css";
import Hero from "../Hero";
import AboutUs from "../AboutUs";
import OurValues from "../OurValues";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimneyWindow,
  faMoneyBillTrendUp,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import Contact from "./Contact";
import { LinkContainer } from "react-router-bootstrap";
function Home({ ...props }) {
  const { isMobile } = props;
  return (
    <>
      <Hero isMobile={isMobile} />
      <AboutUs />
      <OurValues />

      <div className="slanted-divider-home"></div>
      <section className="whatWeDoContainer mt-5 py-3 py-md-5 py-xl-8">
        <div className="container overflow-hidden">
          <div className="row gy-4 gy-md-5 gy-lg-0 align-items-center">
            <div className="col-12 col-lg-5">
              <div className="row">
                <div className="col-12 col-xl-11">
                  <h3 className="fs-6 text-secondary mb-3 mb-xl-4 text-uppercase">
                    What We Do?
                  </h3>
                  <h2 className="display-5 mb-3 mb-xl-4">
                    We are here to provide you with the ideal solution!
                  </h2>
                  <p className="mb-3 mb-xl-4">
                    Reduce your energy bills, minimize your carbon footprint,
                    and embrace a more sustainable lifestyle. Contact us today
                    to learn more about our solar services and schedule a
                    consultation with our experts. Together, let's create a
                    brighter, cleaner, and more sustainable future.
                  </p>
                  <LinkContainer to={{ pathname: "/contact-us", hash: "#top" }}>
                    <Button
                      variant="danger"
                      className="w-100 btn btn-primary-hs text-white btn-lg"
                      type="submit"
                    >
                      Contact us
                    </Button>
                  </LinkContainer>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-7 overflow-hidden">
              <div className="row gy-4 d-flex justify-content-center">
                <div className="col-12 col-sm-6">
                  <div className="card border-0 border-bottom border-danger shadow">
                    <div className="card-body text-center p-4 p-xxl-5">
                      <FontAwesomeIcon
                        icon={faHouseChimneyWindow}
                        className="icon"
                        style={{
                          height: "50px",
                          marginBottom: "10px",
                          color: "var(--primary)",
                        }}
                      />
                      <h4 className="mb-4">Residential Solar</h4>
                      <p className="mb-4 text-secondary">
                        We offer various solar solutions for homes that help
                        reduce energy bills and minimize environmental impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 whatWeDoCard">
                  <div className="card border-0 border-bottom border-danger shadow">
                    <div className="card-body text-center p-4 p-xxl-5">
                      <FontAwesomeIcon
                        icon={faMoneyBillTrendUp}
                        className="icon"
                        style={{
                          height: "50px",
                          marginBottom: "10px",
                          color: "var(--primary)",
                        }}
                      />
                      <h4 className="mb-4">Rent to own</h4>
                      <p className="mb-4 text-secondary">
                        We offer a 3-year solar system rental option, after
                        which you own it!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 whatWeDoCard">
                  <div className="card border-0 border-bottom border-danger shadow">
                    <div className="card-body text-center p-4 p-xxl-5">
                      <FontAwesomeIcon
                        icon={faBuilding}
                        className="icon"
                        style={{
                          height: "50px",
                          marginBottom: "10px",
                          color: "var(--primary)",
                        }}
                      />

                      <h4 className="mb-4">Commercial Solar</h4>
                      <p className="mb-4 text-secondary">
                        We design and install commercial solar systems for
                        businesses and organizations of all sizes, helping them
                        cut costs and enhance sustainability.
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 col-sm-6 whatWeDoCard">
                                    <div className="card border-0 border-bottom border-danger shadow-sm">
                                        <div className="card-body text-center p-4 p-xxl-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="red" className="bi bi-shield-check text-primary mb-4" viewBox="0 0 16 16">
                                                <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                                                <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                            <h4 className="mb-4">Free Support</h4>
                                            <p className="mb-4 text-secondary">We offer free support to all of our clients. This means that you can always get help when you need it, no matter what time it is.</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="slanted-divider-home2"></div>
      <div className="container">
        <Contact />
      </div>
    </>
  );
}

export default Home;
