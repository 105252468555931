import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { setLoggedOut, setUser, setLoggedIn } from "../store/userSlice";
import api from "./api";
import Spinner from "react-bootstrap/Spinner";
import Login from "./pages/Login";

const Protected = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const token = localStorage.getItem("token-husaria");
    const username = localStorage.getItem("username-husaria");

    if (token && username) {
      fetch(api.baseURL + `users/${username}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Not authorized");
          }
        })
        .then((data) => {
          dispatch(setUser(data.user));
          dispatch(setLoggedIn());
        })
        .catch((error) => {
          console.error(error);
          dispatch(setLoggedOut());
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      dispatch(setLoggedOut());
      setIsLoading(false);
    }
  }, [isLoggedIn, dispatch]);

  if (isLoading) {
    return (
      <div className=" w-100 h-100 ms-auto me-auto">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isLoggedIn) {
    return children;
  } else {
    return <Login />;
  }
};

export default Protected;
