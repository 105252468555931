import '../css/Footer.css';
import '../css/theme.css';

import { Button, FloatingLabel } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ContactMeComp from './ContactMeComp';
import { LinkContainer } from 'react-router-bootstrap';

function Footer({ ...props }) {
    const { isMobile } = props;
    return (
        <footer>
            <div className='footerContainer fixed-bottom'>
                <div className={`slanted-dividers ${isMobile ? '' : 'desktop-divider'}`} />
                <div className={`slanted-dividers2 ${isMobile ? '' : 'desktop-divider2'}`} />
                <div className='footerContentContainer'>
                    <div className='footerContentTop'>
                        <div className='row w-100'>
                            <div className={`col-6 col-md-3 mb-3 h-100 ${isMobile ? 'footerSectionMobile' : 'footerSection'}`}>
                                <img
                                    src={process.env.PUBLIC_URL + '/logo/Husaria Solar White logo.png'}
                                    alt="logo"
                                    style={isMobile ? { height: '45px' } : { height: '60px' }}
                                    className="logo-class"
                                />
                                <ul className='nav flex-column'>
                                    <li className='nav-item mb-2'>
                                        <LinkContainer to='/'>
                                            <a href="#" className="nav-link p-0 text-custom-primary">Home</a>
                                        </LinkContainer>
                                    </li>

                                    <li className='nav-item mb-2'>
                                        <LinkContainer to='/products'>
                                            <a href="#" className="nav-link p-0 text-light">Shop</a>
                                        </LinkContainer>
                                    </li>
                                    <li className='nav-item mb-2'>
                                        <LinkContainer to='/contact-us'>
                                            <a href="#" className="nav-link p-0 text-light">Contact us</a>
                                        </LinkContainer>

                                    </li>
                                </ul>
                            </div>
                            {/* <div className={`col-6 col-md-3 mb-3 h-100 ${isMobile ? '' : 'footerSection'} ps-4`}>
                                <h3 style={{ marginTop: '20px' }} >Support</h3>
                                <ul className='nav flex-column'>
                                    <li className='nav-item mb-2'>
                                        <a href="#" className="nav-link p-0 text-custom-primary">F A Q</a></li>
                                </ul>
                            </div> */}
                            <div className='col mb-3 d-flex justify-content-center align-items-center'>
                                {/* <div className='footerInputSection'> */}
                                <div style={isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : { height: '60px', display: 'flex', width: '80%' }}>
                                    <ContactMeComp />
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='footerContentBottom'>
                        <hr className='seperator-style-footer ' />
                        <p>© 2024 Husaria Solar. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer >
    );
}

export default Footer;