import { useEffect, useState } from 'react';
import '../css/App.css';
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";

function MoreDetailModal({ ...props }) {
    const { open, setOpen, data } = props;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [ref, setRef] = useState('');
    const [zip, setZip] = useState('');
    const [shippingFirstName, setShippingFirstName] = useState('');
    const [shippingLastName, setShippingLastName] = useState('');
    const [shippingNumber, setShippingNumber] = useState('');
    const [shippingEmail, setShippingEmail] = useState('');
    const [shippingAddress, setShippingAddress] = useState('');
    const [shippingAddressTwo, setShippingAddressTwo] = useState('');
    const [shippingProvince, setShippingProvince] = useState('');
    const [shippingCity, setShippingCity] = useState('');
    const [shippingZip, setShippingZip] = useState('');

    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (data) {
            setFirstName(data.first_name || '');
            setLastName(data.last_name || '');
            setNumber(data.number || '');
            setEmail(data.email || '');
            setAddress(data.address || '');
            setAddressTwo(data.address_two || '');
            setProvince(data.province || '');
            setCity(data.city || '');
            setZip(data.zip_code || '');
            setShippingFirstName(data.shipping_first_name || '');
            setShippingLastName(data.shipping_last_name || '');
            setShippingNumber(data.shipping_number || '');
            setShippingEmail(data.shipping_email || '');
            setShippingAddress(data.shipping_address || '');
            setShippingAddressTwo(data.shipping_address_two || '');
            setShippingProvince(data.shipping_province || '');
            setShippingCity(data.shipping_city || '');
            setShippingZip(data.shipping_zip_code || '');
            setRef(data.ref || '');
            setProducts(data.items || []);
        }
    }, [data]);

    return (
        <Modal show={open} onHide={() => { setOpen(false) }} >
            <Modal.Header closeButton>
                More details
            </Modal.Header>
            <Modal.Body className='' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <div className='border'></div>
                <h6 className='text-center mt-2 mb-2'>Client information</h6>
                <div className='border'></div>
                <Form className='mt-2 text-font-light'>
                    <Row className='g-2'>
                        <Col md={5}>
                            <Form.Control type='text' placeholder='name'
                                value={firstName} onChange={setFirstName} />
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='last name'
                                value={lastName} onChange={setLastName} />
                        </Col>
                        <Col md={3}>
                            <Form.Control type='text' placeholder='Ref#'
                                value={ref} readOnly/>
                        </Col>
                    </Row>
                    <Row className='g-2 mt-1'>
                        <Col>
                            <Form.Control type='email' placeholder='email'
                                value={email} onChange={setEmail} />
                        </Col>
                        <Col md={5}>
                            <Form.Control type='text' placeholder='number'
                                value={number} onChange={setNumber} />
                        </Col>
                    </Row>
                    <Row className='g-2 mt-1'>
                        <Col>
                            <Form.Control type='text' placeholder='address'
                                value={address} onChange={setAddress} />
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='address two'
                                value={addressTwo} onChange={setAddressTwo} />
                        </Col>
                    </Row>
                    <Row className='g-2 mt-1'>
                        <Col>
                            <Form.Control type='text' placeholder='province'
                                value={province} onChange={setProvince} />
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='city'
                                value={city} onChange={setCity} />
                        </Col>
                        <Col md={2}>
                            <Form.Control type='text' placeholder='zip'
                                value={zip} onChange={setZip} />
                        </Col>
                    </Row>
                </Form>

                <div className='border mt-2'></div>
                <h6 className='text-center mt-2 mb-2'>Products</h6>
                <div className='border'></div>

                <div className='border border-muted mt-2'>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody className='text-font-light'>
                            {products.length === 0 ? (
                                <tr>
                                    <td colSpan="3">No products</td>
                                </tr>
                            ) : (
                                products.map((entry, index) => (
                                    <tr key={index}>
                                        <td>{entry.name}</td>
                                        <td>{entry.price}</td>
                                        <td>{entry.qty}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>

                <div className='border mt-2'></div>
                <h6 className='text-center mt-2 mb-2'>Shipping</h6>
                <div className='border'></div>

                <Form className='mt-2 text-font-light'>
                    <Row className='g-2'>
                        <Col md={5}>
                            <Form.Control type='text' placeholder='name'
                                value={shippingFirstName} onChange={setShippingFirstName} />
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='last name'
                                value={shippingLastName} onChange={setShippingLastName} />
                        </Col>
                    </Row>
                    <Row className='g-2 mt-1'>
                        <Col>
                            <Form.Control type='email' placeholder='email'
                                value={shippingEmail} onChange={setShippingEmail} />
                        </Col>
                        <Col md={5}>
                            <Form.Control type='text' placeholder='number'
                                value={shippingNumber} onChange={setShippingNumber} />
                        </Col>
                    </Row>
                    <Row className='g-2 mt-1'>
                        <Col>
                            <Form.Control type='text' placeholder='address'
                                value={shippingAddress} onChange={setShippingAddress} />
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='address two'
                                value={shippingAddressTwo} onChange={setShippingAddressTwo} />
                        </Col>
                    </Row>
                    <Row className='g-2 mt-1'>
                        <Col>
                            <Form.Control type='text' placeholder='province'
                                value={shippingProvince} onChange={setShippingProvince} />
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='city'
                                value={shippingCity} onChange={setShippingCity} />
                        </Col>
                        <Col md={2}>
                            <Form.Control type='text' placeholder='zip'
                                value={shippingZip} onChange={setShippingZip} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                    onClick={() => { setOpen(false) }}>
                    Close
                </Button>
                <Button variant="danger"
                    className='btn-primary-hs'
                    onClick={() => { setOpen(false) }}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default MoreDetailModal;