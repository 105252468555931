import { faDisplay } from '@fortawesome/free-solid-svg-icons';
import '../css/loading.css';
import { Spinner } from 'react-bootstrap';

function loading({ ...props }) {
    const { loading } = props;
    return (
        // if !loading apply classname with css that gradually lowers opacity
        <div className={`loadingContainer ${loading ? '' : 'loaded'}`}>
            <div className='loadingContentContainer'>
                <img
                    src={process.env.PUBLIC_URL + '/logo/Husaria Solar Without Slogan.png'}
                    alt="logo"
                    className="logo-class"
                />

                <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', width: '60px' }}>
                    <Spinner animation='grow' variant="danger" role="status" size='sm' />
                    <Spinner animation='grow' variant="danger" role="status" size='sm' />
                    <Spinner animation='grow' variant="danger" role="status" size='sm' />
                </div>
            </div>
        </div>
    );
}

export default loading;