import { Button, ButtonGroup, FloatingLabel, Form } from "react-bootstrap";
import "../../css/pages/Products.css";
import { useEffect, useRef, useState } from "react";
import api from "../api";
import catList from "../CategoryList";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem } from "../../store/cartSlice";
const ProductCard = ({ ...props }) => {
  const { product } = props;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [imageHeight, setImageHeight] = useState(null);
  const [inCart, setInCart] = useState(false);
  const imageRef = useRef(null);
  const productUrl = api.baseURL + `/uploads/${product.image_name}`;
  useEffect(() => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
  }, [imageRef]);

  const handleImageLoad = () => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
  };

  const rotateImage = () => {
    if (imageHeight && imageHeight > 350) {
      return (
        <img
          alt="product"
          className="productImage"
          src={productUrl}
          ref={imageRef}
          onLoad={handleImageLoad}
          style={{ transform: "rotate(90deg)" }}
        />
      );
    } else {
      return (
        <img
          alt="product"
          className="productImage"
          src={productUrl}
          ref={imageRef}
          onLoad={handleImageLoad}
        />
      );
    }
  };

  const handleAddToCart = () => {
    const item = {
      id: product._id,
      name: product.title,
      price: Number(product.amount),
      category: product.category,
      qty: 1,
    };
    dispatch(addItem(item));
  };
  const handleRemoveFromCart = () => {
    dispatch(removeItem(product._id));
  };

  useEffect(() => {
    const isInCart = cart.items.some((item) => item.id === product._id);
    setInCart(isInCart);
  }, [cart, product]);

  return (
    <div className="col mt-4">
      <div className="card shadow cardContainer">
        <div className="productImageContainer">{rotateImage()}</div>
        <div className="card-body text-center">
          <div className="cardBodyTitleContainer">
            <h5>{product.title}</h5>
            {/* <div className='vertical-ruler'></div> */}
          </div>
          <h5 className="mt-4">R {product.amount}</h5>
          <div className="productButtonContainer">
            <Button
              variant="danger"
              className="mt-2 btn-primary-hs"
              onClick={() => {
                handleAddToCart();
              }}
            >
              Add to cart
            </Button>
            {inCart ? (
              <Button
                variant="danger"
                className="mt-2 btn-primary-hs"
                onClick={() => {
                  handleRemoveFromCart();
                }}
              >
                <div>
                  {cart.items.filter((item) => item.id === product._id)[0]?.qty}
                  X
                </div>
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function Products({ ...props }) {
  const { isMobile } = props;
  const [categories, setCategories] = useState([]);
  const [productData, setProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  async function fetchProducts() {
    try {
      const response = await fetch(api.baseURL + "products");
      const jsonResponse = await response.json();
      setProductData(jsonResponse.products);
      setFilteredData(jsonResponse.products);
    } catch (error) {
      console.error(error);
    }
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSelectedCategory(value);
  };

  const handleFetchCategories = async () => {
    try {
      const response = await fetch(api.baseURL + "categories/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      if (!response.ok) {
        alert(jsonResponse.error);
        return;
      }
      setCategories(jsonResponse.categories);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedCategory === null || selectedCategory === "Category") {
      setFilteredData(productData);
      return;
    }
    const data = productData.filter(
      (product) => !selectedCategory || product.category === selectedCategory
    );
    setFilteredData(data);
  }, [selectedCategory]);

  useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => {
    handleFetchCategories();
  }, []);
  return (
    <div className="productContainer">
      <section className="py-5 text-center align-middle container-fluid productSection">
        <div className="row py-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="mb-5">Our Products</h1>
            <p className="lead mb-5">
              Need help finding what you're looking for? Our team is here to
              assist you. Simply reach out to us below and we'll get back to you
              promptly.
            </p>
            <div
              style={
                isMobile
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }
                  : {
                      height: "60px",
                      display: "flex",
                    }
              }
            >
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3 text-body-tertiary shadow emailInputHero"
                style={
                  isMobile
                    ? { width: "100%", marginLeft: "auto", marginRight: "auto" }
                    : {}
                }
              >
                <Form.Control type="email" placeholder="name@example.com" />
              </FloatingLabel>
              <Button
                className="shadow productsContactButton"
                variant="primary"
                style={
                  isMobile
                    ? { width: "100%", marginLeft: "auto", marginRight: "auto" }
                    : { width: "200px" }
                }
                size="sm"
              >
                Contact me!
              </Button>
            </div>
          </div>
        </div>
        <div className="slanted-divider"></div>
      </section>
      <div className="album productItemsContainer">
        <div className="container">
          <div className="w-100 d-flex justify-content-end">
            <Form.Select
              name="category"
              onChange={handleInputChange}
              style={{ width: "250px" }}
            >
              <option>Category</option>
              {categories.map((item, index) => (
                <option key={index}>{item.name}</option>
              ))}
            </Form.Select>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            {filteredData.length === 0 ? (
              <div
                className="shadow"
                style={{
                  width: "100%",
                  height: "450px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <h5>No products available</h5>
              </div>
            ) : (
              filteredData.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
