import '../css/Hero.css';
import { Card, Button, Container, FloatingLabel } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ContactMeComp from './ContactMeComp';

function Hero({ ...props }) {
    const { isMobile } = props;
    return (
        <Card className='hero-section dark-filter'>
            <div className='hero-background'>
                <Card.Body className={isMobile ? 'heroMobileBodyContent' : ''}>
                    <Container className={isMobile ? 'hero-mobile' : 'hero-content'}>
                        <div style={{ width: '80%' }}>
                            <h1>Switching Off, Staying On</h1>
                        </div>
                        <div className='heroInput'>
                            <ContactMeComp />
                            {/* <FloatingLabel controlId="floatingInput"
                                label="Email address"
                                className="mb-3 text-body-tertiary shadow emailInputHero">
                                <Form.Control type="email"
                                    placeholder="name@example.com" />
                            </FloatingLabel>
                            <Button className='shadow btn-primary-hs'
                                variant="danger"
                                size='lg'>
                                Contact me!
                            </Button> */}
                        </div>
                    </Container>
                </Card.Body>
                <div className='slanted-divider'></div>
            </div>
        </Card>
    );
}

export default Hero;