import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faLock, faLightbulb, faBolt } from '@fortawesome/free-solid-svg-icons';

function OurValues() {
    return (
        <div className="container px-4 py-5" id="featured-3" style={{zIndex: 1000}}>
            <h2 className="pb-2 border-bottom">Our Values</h2>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-4">
                <div className="feature col">
                    <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                        <FontAwesomeIcon icon={faLeaf} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />
                    </div>
                    <h3 className="fs-2 text-body-emphasis">Sustainability</h3>
                    <p>We are dedicated to promoting environmental responsibility and reducing carbon emissions through the widespread adoption of solar power.</p>
                </div>
                <div className="feature col">
                    <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                        <FontAwesomeIcon icon={faLock} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />

                    </div>
                    <h3 className="fs-2 text-body-emphasis">Integrity</h3>
                    <p>We conduct our business with honesty, transparency, and integrity, building trust and fostering long-term relationships with our customers, partners, and stakeholders.</p>
                </div>
                <div className="feature col">
                    <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                        <FontAwesomeIcon icon={faLightbulb} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />

                    </div>
                    <h3 className="fs-2 text-body-emphasis">Innovation</h3>
                    <p>We embrace innovation and embrace new technologies to continuously improve our products and services, staying at the forefront of the rapidly evolving solar industry.</p>
                </div>
                <div className="feature col">
                    <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
                        <FontAwesomeIcon icon={faBolt} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />

                    </div>
                    <h3 className="fs-2 text-body-emphasis">Empowerment</h3>
                    <p>We empower individuals and businesses to take control of their energy future, providing the knowledge, tools, and support they need to make informed decisions and achieve their sustainability goals.</p>
                </div>
            </div>
        </div>
    );
}

export default OurValues;