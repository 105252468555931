import { Accordion, Button } from "react-bootstrap";
import "../../css/pages/AdminLoggedIn.css";
import { useEffect, useState } from "react";
import AddProduct from "./AddProduct";
import ContactMeTable from "./ContactMeTable";

import api from "../api";
import AdminActiveOrders from "../AdminActiveOrders";
import AdminConfirmedOrders from "../AdminConfirmedOrders";
import AdminCompleteOrders from "../AdminCompleteOrders";
import MoreDetailModal from "../MoreDetailModal";
import RemoveProduct from "./RemoveProduct";

function AdminLoggedIn() {
  const token = localStorage.getItem("token-husaria") ?? null;
  const [open, setOpen] = useState(false);
  const [moreDetailData, setMoreDetailData] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);

  const accordianStyle = {
    maxHeight: "300px",
    overflowY: "auto",
  };

  function ClearStorage() {
    localStorage.setItem("username-husaria", null);
    localStorage.setItem("token-husaria", null);
    window.location = "/";
  }

  async function getActiveOrders() {
    try {
      const response = await fetch(api.baseURL + "orders/active", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      if (!response.ok) {
        throw new Error(jsonResponse.error);
      }
      setActiveOrders(jsonResponse.ordersEntries.reverse());
    } catch (error) {
      console.error(error);
    }
  }

  async function getConfirmedOrders() {
    try {
      const response = await fetch(api.baseURL + "orders/confirmed", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      if (!response.ok) {
        throw new Error(jsonResponse.error);
      }
      setConfirmedOrders(jsonResponse.ordersEntries.reverse());
    } catch (error) {
      console.error(error);
    }
  }

  async function getCompletedOrders() {
    try {
      const response = await fetch(api.baseURL + "orders/complete", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      if (!response.ok) {
        throw new Error(jsonResponse.error);
      }
      setCompletedOrders(jsonResponse.ordersEntries.reverse());
    } catch (error) {
      console.error(error);
    }
  }

  async function refreshOrderTables() {
    await getActiveOrders();
    await getConfirmedOrders();
    await getCompletedOrders();
  }

  useEffect(() => {
    getActiveOrders();
    getConfirmedOrders();
    getCompletedOrders();
  }, []);

  return (
    <div className="admin-logged-container">
      <MoreDetailModal open={open} setOpen={setOpen} data={moreDetailData} />
      <div className="w-100 d-flex justify-content-end">
        <Button
          variant="danger"
          className="btn-primary-hs"
          onClick={() => {
            ClearStorage();
          }}
        >
          Logout
        </Button>
      </div>
      <div className="admin-logged-content mt-2">
        <Accordion
          className="shadow table-container"
          flush
          style={{ width: "80%" }}
        >
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Add product</Accordion.Header>
            <Accordion.Body>
              <AddProduct token={token} />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Remove products</Accordion.Header>
            <Accordion.Body>
              <RemoveProduct />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={2}>
            <Accordion.Header>Contact Me</Accordion.Header>
            <Accordion.Body>
              <ContactMeTable />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={3}>
            <Accordion.Header>Active Orders</Accordion.Header>
            <Accordion.Body style={accordianStyle}>
              <AdminActiveOrders
                setOpen={setOpen}
                setData={setMoreDetailData}
                orders={activeOrders}
                getOrders={getActiveOrders}
                refresh={refreshOrderTables}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={3}>
            <Accordion.Header>Confirmed Orders</Accordion.Header>
            <Accordion.Body style={accordianStyle}>
              <AdminConfirmedOrders
                setOpen={setOpen}
                setData={setMoreDetailData}
                orders={confirmedOrders}
                getOrders={getConfirmedOrders}
                refresh={refreshOrderTables}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={4}>
            <Accordion.Header>Completed Orders</Accordion.Header>
            <Accordion.Body>
              <AdminCompleteOrders
                setOpen={setOpen}
                setData={setMoreDetailData}
                orders={completedOrders}
                getOrders={getCompletedOrders}
                refresh={refreshOrderTables}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default AdminLoggedIn;
