import '../../css/pages/Contact.css';

function ContactHome() {
    return (
        <div className='contactContainer container-fluid'>
            <div className="container-fluid">
                <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-start">
                    <div className="col-12 col-lg-6 col-xl-7">
                        <div className="row justify-content-xl-center">
                            <div className="col-12 col-xl-11 contactPageInfo">
                                <h2 className="mb-3 border-bottom"><span>When</span> and <span>where</span> to find us.</h2>

                                <h4 className='p-3 border-bottom'>Call us</h4>
                                <p><span>Office:</span></p>
                                <p className='ms-5'><span className='me-2'>Tel:</span>011 760 1130</p>

                                <p><span>Sales:</span></p>
                                {/* <p className='ms-5 text-decoration-underline'>Chris Freeman</p>
                                <p className='ms-5'><span className='me-2'>Mobile:</span>072 558 8127</p>
                                <p className='ms-5'><span className='me-2'>Email:</span>chris@husaria.co.za</p> */}

                                <p className='ms-5 text-decoration-underline'>Alex Boryna</p>
                                <p className='ms-5'><span className='me-2'>Email:</span>alex@husaria.co.za</p>

                                <p className='ms-5 text-decoration-underline'>General Enquiries/Admin</p>
                                <p className='ms-5'><span className='me-2'>Email:</span>info@husaria.co.za</p>

                                <h4 className='p-3 border-bottom'>Location</h4>
                                <p className='ms-5'>189 Ontdekkers Rd, Horizon Park, Roodepoort, 1724</p>
                                <h4 className='p-3 border-bottom'>Buisness hours</h4>
                                <p className='ms-5'>Mon-Thu: <span className='ms-2'>8:00-16:00</span></p>
                                <p className='ms-5'>Fri: <span className='ms-2'>8:00-15:00</span></p>
                                <p className='ms-5'>Sat-Sun: <span className='ms-2'>Closed</span></p>
                                <p className='ms-5'>Public Holidays: <span className='ms-2'>Closed</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5 d-flex align-content-center" style={{height: '80vh'}}>
                        <div className='mapContainer' style={{ height: '100%' }}>
                            <iframe title="Location Map"
                                className='shadow-lg'
                                style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                                loading="lazy"
                                allowFullScreen
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.675728242455!2d27.8736212!3d-26.142114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e959f794a9c6105%3A0x2e951266ead54f25!2s189%20Ontdekkers%20Rd%2C%20Horison%20Park%2C%20Roodepoort%2C%201724!5e0!3m2!1sen!2sza!4v1715070858075!5m2!1sen!2sza"
                            ></iframe>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ContactHome;