import { useState } from "react";
import { Button, FormControl, Modal, Spinner } from "react-bootstrap";
import api from "../api";
import { setLoggedIn, setUser } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
function Login() {
  const [show, setShow] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigator = useNavigate();
  const dispatch = useDispatch();

  async function handleSubmit() {
    try {
      setLoadingLogin(true);
      const response = await fetch(api.baseURL + "login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      const jsonResponse = await response.json();
      if (!response.ok) {
        alert(jsonResponse.error);
        setLoadingLogin(false);
        return;
      }
      localStorage.setItem("username-husaria", username);
      localStorage.setItem("token-husaria", jsonResponse.token);
      dispatch(setUser(jsonResponse.token));
      dispatch(setLoggedIn());
      alert("Logged in!");
      setShow(false);
      navigator('/admin');
    } catch (error) {
      alert("Error logging in: ", error);
    }
  }

  return (
    <Modal
      show={show}
      size="sm"
      className="text-font-light"
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>Admin Login</Modal.Header>
      <Modal.Body>
        <div className="p-2">
          <FormControl
            type="text"
            className="mb-2"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            placeholder="Username"
          />
          <FormControl
            type="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            placeholder="Password"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          className="btn-primary-hs"
          onClick={() => {
            setShow(false);
          }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: "70px" }}
          onClick={() => {
            handleSubmit();
          }}
        >
          {loadingLogin ? (
            <Spinner animation="border" size="sm" variant="light" />
          ) : (
            "Login"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Login;
