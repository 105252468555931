import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    total: 0,
    quantity: 0,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action) => {
            const item = action.payload;
            const existingItem = state.items.findIndex(
                (cartItem) => cartItem.id === item.id
            );

            if (existingItem !== -1) {
                state.items[existingItem].qty += 1;
            } else {
                state.items.push(item);
            }
            state.total += item.price;
            state.quantity += 1;
        },
        removeItem: (state, action) => {
            const itemId = action.payload;
            const existingItem = state.items.findIndex(
                (cartItem) => cartItem.id === itemId
            );

            if (existingItem !== -1) {
                const item = state.items[existingItem];
                state.total -= item.price * item.qty;
                state.quantity -= item.qty;
                state.items.splice(existingItem, 1);
            }
        },
        clearCart: (state) => {
            state.items = [];
            state.total = 0;
            state.quantity = 0;
        }
    }
});

export const { addItem, removeItem, clearCart } = cartSlice.actions;
export default cartSlice.reducer;