import '../css/MobileNav.css';
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered, faTimes, faHome, faCartShopping, faLayerGroup, faPhone, faUserTie } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';

function MobileNav({ ...props }) {
    const { isMobile } = props;
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const cart = useSelector((state) => state.cart);

    const handleOffcanvasToggle = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    const handleOpenToggle = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    useEffect(() => {}, [cart]);

    return (
        <header >
            <div className="mobile-nav-container">
                {isMobile ?
                    <>
                        <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="start">
                            <Offcanvas.Header style={{ width: '100%', justifyContent: 'center' }}>
                                <img
                                    src={process.env.PUBLIC_URL + '/logo/Husaria Solar Without Slogan.png'}
                                    alt="logo"
                                    className="logo-class"
                                />
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="d-flex flex-column h-100">
                                    <Nav className="navigation-container">
                                        <LinkContainer to='/' onClick={handleOffcanvasToggle}>
                                            <Nav.Link className='text-dark'>
                                                <FontAwesomeIcon icon={faHome}
                                                    className="icon"
                                                    style={{
                                                        marginRight: '10px',
                                                        color: 'var(--primary)'
                                                    }} />
                                                Home
                                            </Nav.Link>
                                        </LinkContainer>

                                        <LinkContainer to='/contact-us' onClick={handleOffcanvasToggle}>
                                            <Nav.Link className='text-dark'>
                                                <FontAwesomeIcon icon={faPhone} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />
                                                Contact us
                                            </Nav.Link>
                                        </LinkContainer>

                                        <hr className='d-lg-none seperator-style' />
                                        <LinkContainer to='/products' onClick={handleOffcanvasToggle}>
                                            <Nav.Link className='text-dark'>
                                                <FontAwesomeIcon icon={faLayerGroup} className="icon" style={{ marginRight: '10px' }} />
                                                Shop
                                            </Nav.Link>
                                        </LinkContainer>
                                        <hr className='d-lg-none seperator-style' />
                                        <LinkContainer to='/checkout' onClick={handleOffcanvasToggle}>
                                            <Nav.Link className='text-dark'>
                                                <FontAwesomeIcon icon={faCartShopping} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />
                                                Cart
                                                <span className="badge bg-danger rounded-pill ms-2">{cart.quantity ? cart.quantity : 0}</span>
                                            </Nav.Link>
                                        </LinkContainer>
                                    </Nav>
                                    <Nav className="mt-auto">
                                        <LinkContainer to='/admin'>
                                            <Nav.Link className='me-5'>
                                                <FontAwesomeIcon icon={faUserTie} className="icon" />
                                            </Nav.Link>
                                        </LinkContainer>
                                    </Nav>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                        <button className="menu-toggle-btn" onClick={handleOpenToggle}>
                            <FontAwesomeIcon icon={showOffcanvas ? faTimes : faBarsStaggered} className="menu-icon" />
                        </button>
                    </> :
                    <Navbar className='shadow' bg="body-tertiary" fixed="top">
                        <Nav.Link className='ms-5'>
                        </Nav.Link>
                        <Container>
                            <LinkContainer to='/'>
                                <Nav.Link className='text-dark'>
                                    <FontAwesomeIcon icon={faHome} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />
                                    Home
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/products'>
                                <Nav.Link className='text-dark'>
                                    <FontAwesomeIcon icon={faLayerGroup} className="icon" style={{ marginRight: '10px' }} />
                                    Shop
                                </Nav.Link>
                            </LinkContainer>

                            <Navbar.Brand>
                                <img
                                    src={process.env.PUBLIC_URL + '/logo/Husaria Solar Without Slogan.png'}
                                    alt="logo"
                                    className="logo-class"
                                />
                            </Navbar.Brand>

                            <hr className='d-lg-none seperator-style' />
                            <LinkContainer to='/contact-us'>
                                <Nav.Link className='text-dark'>
                                    <FontAwesomeIcon icon={faPhone} className="icon" style={{ marginRight: '10px' }} />
                                    Contact us
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/checkout'>
                                <Nav.Link className='text-dark'>
                                    <FontAwesomeIcon icon={faCartShopping} className="icon" style={{ marginRight: '10px', color: 'var(--primary)' }} />
                                    Cart
                                    <span className="badge bg-danger rounded-pill ms-2">{cart.quantity ? cart.quantity : 0}</span>

                                </Nav.Link>
                            </LinkContainer>
                        </Container>
                        <LinkContainer to='/admin'>
                            <Nav.Link className='me-5'>
                                <FontAwesomeIcon icon={faUserTie} className="icon" />
                            </Nav.Link>
                        </LinkContainer>
                    </Navbar>
                }
            </div>
        </header>
    );
}

export default MobileNav;
