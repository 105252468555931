function AboutUs() {
    return (
        <section className="py-3 py-md-5">
            <div className="container">
                <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
                    <div className="col-12 col-lg-6 col-xl-5">
                        <img className="img-fluid rounded shadow" loading="lazy" src={`${process.env.PUBLIC_URL}/images/pexels-kindel-media-9875420.jpeg`} alt="About 1" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-7">
                        <div className="row justify-content-xl-center">
                            <div className="col-12 col-xl-11">
                                <h2 className="mb-3 border-bottom">About us</h2>
                                <p className="lead fs-4 text-secondary mb-5">
                                    Husaria Solar provides solar solutions and services for power production and storage. Started in 2019 by entrepreneur Alex Boryna, we offer planning, design, distribution, and turnkey supply of photovoltaic installations. Our goal is to lead South Africa's transition to solar energy and we provide exceptional after-sales support and technical services.
                                </p>
                                <div className="row gy-4 gy-md-0 gx-xxl-5X">
                                    <div className="col-12 col-md-6">
                                        <div className="d-flex">
                                            <div className="me-4 text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    width="32" height="32" fill='red'
                                                    viewBox="0 0 24 24"><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                                                </svg>
                                            </div>
                                            <div>
                                                <h2 className="h4 mb-3">Our vision</h2>
                                                <p className="text-secondary mb-0">To make clean, renewable energy accessible to all by harnessing the power of the sun, creating a brighter, more sustainable world for future generations.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="d-flex">
                                            <div className="me-4 text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    width="32" height="32" fill="red" className="bi bi-fire" viewBox="0 0 16 16">
                                                    <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
                                                </svg>
                                            </div>
                                            <div>
                                                <h2 className="h4 mb-3">Our mission</h2>
                                                <p className="text-secondary mb-0">To provide innovative solar solutions that exceed customer expectations, making solar energy accessible and affordable for everyone.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mt-5">
                                        <div className="d-flex">
                                            <div className="me-4 text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill='red'><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z" /></svg>
                                            </div>
                                            <div>
                                                <h2 className="h4 mb-3">Why we're different</h2>
                                                <p className="text-secondary mb-0">We prioritize quality, reliability, and customer satisfaction. Our team of skilled experts design and install custom solar systems to meet unique client needs.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </section >);
}

export default AboutUs;