import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import "../../css/pages/AddProduct.css";
import "../../css/App.css";

import { useEffect, useState } from "react";
import catList from "../CategoryList";
import api from "../api";

function AddProduct({ ...props }) {
  const { token } = props;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [show, setShow] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    category: "",
    amount: "",
    file: null,
  });

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      file: event.target.files[0],
    });
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedCategory(value);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCategoryChange = (e) => {
    console.log(e.target.value);
    setNewCategory(e.target.value);
  };

  const handleDeleteCategory = async () => {
    try {
      const category = categories.find(
        (entry) => entry.name === selectedCategory
      );
      const id = category ? category._id : null;
      const response = await fetch(api.baseURL + `categories/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      await handleFetchCategories();
      alert(jsonResponse.message ? jsonResponse.message : jsonResponse.error);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleAddCategory = async () => {
    try {
      const response = await fetch(api.baseURL + `categories`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ catName: newCategory }),
      });
      const jsonResponse = await response.json();
      await handleFetchCategories();
      setNewCategory("");
      alert(jsonResponse.message ? jsonResponse.message : jsonResponse.error);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFetchCategories = async () => {
    try {
      const response = await fetch(api.baseURL + "categories/", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const jsonResponse = await response.json();
      if (!response.ok) {
        alert(jsonResponse.error);
        return;
      }
      setCategories(jsonResponse.categories);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("title", formData.title);
    data.append("category", formData.category);
    data.append("amount", formData.amount);
    data.append("file", formData.file);

    try {
      const response = await fetch(api.baseURL + "upload-product", {
        method: "POST",
        body: data,
      });
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
      console.log("File uploaded successfully");
      setFormData({
        title: "",
        category: "",
        amount: "",
        file: null,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  useEffect(() => {
    handleFetchCategories();
  }, []);

  return (
    <div className="add-product-container">
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Category management</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border"></div>
          <h6 className="text-center mt-2 mb-2">Add category</h6>
          <div className="border"></div>
          <div className="d-flex mt-2">
            <Form.Group controlId="formFile">
              <Form.Control
                type="text"
                placeholder="category name"
                name="newCategory"
                value={newCategory}
                onChange={handleCategoryChange}
              />
            </Form.Group>
            <Button
              className="ms-2 btn-primary-hs"
              variant="danger"
              onClick={() => {
                handleAddCategory();
              }}
            >
              +
            </Button>
          </div>
          <div className="border mt-2"></div>
          <h6 className="text-center mt-2 mb-2">Delete category</h6>
          <div className="border mb-2"></div>
          <div className="d-flex">
            <Form.Select
              name="category"
              value={selectedCategory}
              onChange={handleSelectChange}
            >
              <option>Category</option>
              {categories.map((item, index) => (
                <option key={index}>{item.name}</option>
              ))}
            </Form.Select>
            <Button
              className="ms-2 btn-primary-hs"
              variant="danger"
              disabled={selectedCategory === "Category"}
              onClick={() => {
                handleDeleteCategory();
              }}
            >
              -
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          <Button variant="danger" className="btn-primary-hs">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row align-items-end">
        <div className="col">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </div>
        <div className="col-8">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row align-items-end">
        <div className="col d-flex">
          <Form.Select
            name="category"
            value={formData.category}
            onChange={handleInputChange}
          >
            <option>Category</option>
            {categories.map((item, index) => (
              <option key={index}>{item.name}</option>
            ))}
          </Form.Select>
          <Button
            className="ms-2 btn-primary-hs"
            variant="danger"
            onClick={() => {
              setShow(true);
            }}
          >
            +
          </Button>
        </div>
        <div className="col">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">R</InputGroup.Text>
            <Form.Control
              placeholder="Amount"
              aria-label="Amount"
              aria-describedby="basic-addon1"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
            />
          </InputGroup>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-end pt-3">
        <Button
          className="btn-primary-hs"
          variant="danger"
          onClick={(event) => {
            handleSubmit(event);
          }}
        >
          Create product
        </Button>
      </div>
    </div>
  );
}

export default AddProduct;
