import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isLoggedIn: false,
    user: null,
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setLoggedIn: (state) => {
            state.isLoggedIn = true;
        },
        setLoggedOut: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            localStorage.clear();
            console.log('User logged out.');
        }
    }
});

export const { setUser, setLoggedIn, setLoggedOut } = authSlice.actions;
export default authSlice.reducer;