import '../css/App.css';
import '../css/AdminTables.css';
import { useEffect, useState } from 'react';
import { Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';

import api from './api';

function AdminActiveOrders({ ...props }) {
    const token = localStorage.getItem('token-husaria') ?? null;
    const { setOpen, setData, orders, getOrders, refresh } = props;

    async function updateOrder(rowData) {
        try {
            const response = await fetch(api.baseURL + `orders/update/${rowData._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            const jsonResponse = await response.json();
            if (!response.ok) {
                throw new Error(jsonResponse.error);
            }
            await refresh();
        } catch (error) {
            console.error(error);
        }
    }

    async function deleteOrder(rowData) {
        try {
            const response = await fetch(api.baseURL + `orders/active/${rowData._id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            const jsonResponse = await response.json();
            if (!response.ok) {
                throw new Error(jsonResponse.error);
            }
            alert('Order removed');
            await getOrders();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => { }, [orders]);

    return (
        <Table className="shadow-sm">
            <thead>
                <tr>
                    <th align='align-center'>Ref#</th>
                    <th align='left'>First</th>
                    <th align='left'>Last</th>
                    <th align='left'>Phone number</th>
                    <th align='left'>Email</th>
                    <th align='align-center'>Status</th>
                    <th className="align-left"></th>
                    <th className="align-left"></th>
                    <th className="align-center">Details</th>
                    <th className="align-center">
                        <Button size='sm' className='m-0' onClick={() => { getOrders() }}>
                            <FontAwesomeIcon icon={faRotateRight} className="icon text-light text-center" />
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody className='text-font-light'>
                {orders.map((entry, index) => {
                    const status = entry.payment_status ? 'payed' : 'not payed';
                    return (
                        <tr key={index}>
                            <td>{entry.ref}</td>
                            <td>{entry.first_name}</td>
                            <td>{entry.last_name}</td>
                            <td>{entry.number}</td>
                            <td>{entry.email}</td>
                            <td>{status}</td>
                            <td align='left'>
                                <Button variant="success"
                                    className="d-flex align-items-center"
                                    size="sm"
                                    onClick={() => { updateOrder(entry); }}>
                                    Confirm
                                </Button>
                            </td>
                            <td align='left'>
                                <Button variant="link"
                                    className="d-flex text-danger align-items-center"
                                    size="sm"
                                    onClick={() => { deleteOrder(entry); }}>
                                    delete
                                </Button>
                            </td>
                            <td align='middle'>
                                <Button variant="link"
                                    className="d-flex align-items-center"
                                    size="sm"
                                    onClick={() => { setData(entry); setOpen(true) }}>
                                    more info
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

export default AdminActiveOrders;