import "../css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";
import MobileNav from "./MobileNav";
import Loading from "./loading";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Footer";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Checkout from "./pages/Checkout";
import ContactUs from "./pages/ContactUs";
import Protected from "./Protected";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import AdminLoggedIn from "./pages/AdminLoggedIn";

function App() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isLoading, setIsLoading] = useState(false);

  const [loginState, setLoginState] = useState(false);
  const loggedin = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    setLoginState(loggedin);
  }, [loggedin]);

  useEffect(() => {
    const script = document.createElement("script");
    // script.src = 'https://www.payfast.co.za/onsite/engine.js';
    script.src = "https://sandbox.payfast.co.za/onsite/engine.js";

    script.async = true;
    script.onload = () => {
      console.log("Script loaded successfully");
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2500));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {isLoading ? (
          <Loading loading={isLoading} />
        ) : (
          <>
            <MobileNav isMobile={isMobile} />
            <main
              className="contentContainer"
              style={{
                paddingTop: isMobile ? "0" : "75px",
              }}
            >
              <Routes>
                <Route path="/" element={<Home isMobile={isMobile} />} />
                <Route path="/products" element={<Products />} />
                <Route
                  path="/checkout"
                  element={<Checkout isMobile={isMobile} />}
                />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route
                  path="/admin"
                  element={
                    <Protected isLoggedIn={loginState} children={<AdminLoggedIn />} />
                  }
                />
                <Route path="/login" element={<Login />} />
              </Routes>
            </main>
            <Footer isMobile={isMobile} />
          </>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
