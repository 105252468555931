import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import api from "../api";

function ContactMeTable() {
    const [contactData, setContactData] = useState([]);

    async function contactClick(email) {
        try {
            const response = await fetch(api.baseURL + 'delete-email', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            });
            const jsonResponse = await response.json();
            if (!response.ok) {
                alert(jsonResponse.error);
                return;
            }
            alert('Email removed from contact database.');
            fetchContactData();
        } catch (error) {
            console.error(error);
        }
    }
    
    async function fetchContactData() {
        try {
            const response = await fetch(api.baseURL + 'fetch-emails', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const jsonResponse = await response.json();
            setContactData(jsonResponse.entries);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => { fetchContactData() }, []);
    return (
        <Table className="shadow-sm">
            <thead>
                <tr>
                    <th>Email</th>
                    <th>Contacted</th>
                </tr>
            </thead>
            <tbody>
                {contactData.map((entry, index) => (
                    <tr key={index}>
                        <td>{entry.email}</td>
                        <td>
                            <Button variant="danger"
                                className="btn-primary-hs d-flex align-items-center"
                                size="sm"
                                onClick={() => { contactClick(entry.email); }}>
                                Contacted
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default ContactMeTable;