import '../../css/pages/ContactUs.css';
import ContactMeComp from '../ContactMeComp';
import ContactHome from './Contact';

function ContactUs() {
    return (
        <div className='contactUsContainer' id='top'>
            <section className='py-5 text-center align-middle container-fluid contactUsSection'>
                <div className='row py-lg-5'>
                    <div className='col-lg-6 col-md-8 mx-auto'>
                        <h1 className='mb-5 text-center'>Contact us</h1>
                        <p className='lead mb-5'>
                            Need help finding what you're looking for?
                            Our team is here to assist you.
                            Simply reach out to us below and we'll get back to you
                            promptly.
                        </p>
                    </div>
                </div>
                <div className='slanted-divider'></div>
            </section>
            <div className='container contactUsContentContainer'>
                <div className='heroInput'>
                    <ContactMeComp />
                </div>
                <ContactHome />
            </div>
        </div>
    );
}

export default ContactUs;