import { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import validateEmailInput from '../functions/emailCheck';
import api from "./api";

function ContactMeComp() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    const handleEmailChange = (event) => {
        const { value } = event.target;
        const errorMessage = validateEmailInput(value);
        setError(errorMessage);
        setEmail(value);
    };

    async function handleSubmit() {
        if (error || !email) {
            alert('Please fill in a valid email address');
            return;
        }
        const response = await fetch(api.baseURL + 'submit-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ emailData: email }),
        });
        const jsonResponse = await response.json();
        console.log(jsonResponse);
        if (!response.ok) {
            if (jsonResponse.error?.code === 11000) {
                alert("You're already in our systems, we'll be in contact shortly.");
                return;
            }
            alert('Error submitting email.');
            return;
        }
        setEmail('');
        alert("We'll be in contact shortly!");
    }

    return (
        <div className='emailInputContainer'>
            <FloatingLabel controlId="floatingInput"
                label={error ? <Form.Text className="text-danger">{error}</Form.Text> : "Email address"}
                className="mb-2 text-body-tertiary shadow emailInputHero">
                <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={handleEmailChange}
                />
            </FloatingLabel>
            <Button className='shadow btn-primary-hs p-3 me-1'
                variant="danger"
                size='md'
                style={{ width: '100%' }}
                onClick={() => { handleSubmit() }}>
                Contact me!
            </Button>
        </div>
    );
}

export default ContactMeComp;